const environment = {
  BACKEND_BASE_URL: "http://localhost:8080",
  MORALIS_APP_ID: "Cong0Rh8UwDPV5HC4U0nCPxiwEz5PAxeFLP2f3i0",
  MORALIS_SERVER_URL: "https://nbnob0befkpo.usemoralis.com:2053/server",
  NFT_CONTRACT: {
    11155111: "0x799e8F5419bB914cDb600c419C0B4F972C8578d9",
    56: "0x983e398513489a80EA5B9c7EDBdD83bF6dBE5659",
    "eip155:56": "0x983e398513489a80EA5B9c7EDBdD83bF6dBE5659",
  },
  PUBLIC_RPC: {
    11155111: "https://ethereum-sepolia.publicnode.com",
    56: "https://binance.llamarpc.com",
  },
  TOKEN_CONTRACT: {
    11155111: "0x609a789Df70B83c6b0FDC1E47169d7fc538a3524",
    56: "0xD6eF2222Cc850fDc7Ee30F2B2D5384e0167700A3",
    "eip155:56": "0xD6eF2222Cc850fDc7Ee30F2B2D5384e0167700A3",
  },
  TOKEN_DECIMALS: 18,
  DEFAULT_CHAIN: 56,
  DEFAULT_HEX_CHAIN: "0x38",
  TOKEN_URI:
    "https://gateway.pinata.cloud/ipfs/QmNgmPe99stePaKbQGUiYbjHnxkERWpkdtqV4hkoPknxLD/",
  OWNER_STARK_KEY:
    "0x62120df211c64e8e1f84eb3c04fadd223c3ca7a76284e47694805c4b767b923",
  REQUEST_ID: "3daa9bc9-a2a0-48de-9cc7-623c04ca7c34",
  PROJECT_ID: "32",
  GROUP_REQUEST_ID: "7257d29c-c96a-4302-8eaf-368a0d62b977",
  NFT_CONTRACT: "0x0ff39AeDC8E6a37212F8Fc96bc5c107AB6D1990A",
  SENDER_ADDRESS: "0xa881eF338fCfE85cB64f68e2AeA0b8664131055c",
  MYRIA_PRIVATE_KEY:
    "59b2b64a1c915a6613fe3dbd26fb75d1ea0e519786717a9a1ab18810379ff64",
    NETWORK:"MAINNET"
};

export default environment;
