import React, { useEffect, useState } from "react";
import {
  CardInnerDiv2,
  CardWrapper2,
  ImgWrapper,
  MainSection,
  MainWrapper,
  Mintbtn,
  Name,
  NftId,
  Nftvalue,
  Text,
  TextDiv2,
  Title,
} from "./elements";
import {
  MainCol,
  MainContainer,
  MainRow,
} from "../../common/mainStyle/mainStyle";
import { Image } from "react-bootstrap";
import {
  collectioncardimg,
  collectionimg1,
  heroimg,
  video3,
} from "../../../assets";
import { useWalletConnectClient } from "services/walletServices";
import { CommonUtility } from "utility/common";
import { nftAbi } from "utility/contract/NftAbi";
import environment from "environment";
import { erc20Abi } from "utility/contract";
import { ToastMessage } from "components/common";
import { v4 as uuidv4 } from "uuid";

import {
  DeveloperAccountManager,
  EnvTypes,
  ModuleFactory,
  MyriaClient,
  Network,
  OnchainAssetManager,
  TokenType,
  WalletManager,
} from "myria-core-sdk";
import useMetamask from "helpers/useMetamask";
import Loading from "components/common/loaders/loading";
import MessageModal from "components/common/modal/messageModal";
import { Modal } from "antd";
import MintNftModal from "components/common/modal/mintnftModal";

const HeroComp = ({ userAddress, isWalletConnected, userStarkKey }) => {
  const {
    isConnected,
    starkKey,
    connectL2Wallet,
    walletAdrress,
    myriaClient,
    netWorkIdUser,
    setNetWorkIdByUser,
    loginWallet,
  } = useMetamask();

  // const { web3Provider, account, chain } = useWalletConnectClient();
  const [isLoading, setIsLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  // const mintNft = async () => {
  //   try {
  //     setIsLoading(true);
  //     if (!web3Provider) throw "Please connect wallet";
  //     const contract = CommonUtility.contract(
  //       web3Provider,
  //       nftAbi,
  //       environment.NFT_CONTRACT[chain]
  //     );
  //     const token = await contract.methods.RetroToken().call();
  //     const tokenContract = CommonUtility.contract(
  //       web3Provider,
  //       erc20Abi,
  //       token
  //     );

  //     const currentSupply = await contract.methods.totalSupply().call();
  //     const maxSupply = await contract.methods.MAX_SUPPLY().call();
  //     if (+currentSupply >= +maxSupply) throw "All Nfts Minted";
  //     const hasMinted = await contract.methods.getMintStatus(account).call();
  //     if (hasMinted) throw "Already minted NFT";
  //     const walletLimit = await contract.methods.MAX_MINT_PER_WALLET().call();
  //     const nftBalance = await contract.methods.balanceOf(account).call();
  //     if (+nftBalance >= +walletLimit) throw "Your claim Limit reached";
  //     const userBalance = await tokenContract.methods.balanceOf(account).call();
  //     const minBalanceLimit = await contract.methods.MIN_RETRO_BALANCE().call();
  //     if (+userBalance < +minBalanceLimit) throw "Not eligible for minting";
  //     const txn = await contract.methods.mint(account).send({ from: account });
  //     if (txn && txn.code == 4001) throw "User denied the transaction";
  //     if (!txn.status) throw "Transaction Failed";
  //     if (txn.status) {
  //       await getAllNfts();
  //       setIsLoading(false);
  //       ToastMessage("Success!", "Transaction successful", "success");
  //     }
  //   } catch (error) {
  //     console.log("mintNft error", error);
  //     setIsLoading(false);
  //     if (error?.message) {
  //       ToastMessage("Error", error?.message, "error");
  //     } else {
  //       ToastMessage("Error", error, "error");
  //     }
  //   }
  // };
  //set card data here
  const prepareNftData = async () => {
    try {
      let arr = [];
      if (userAddress) {
        const data = await getUserCurrentAssets();
        if (data && data?.length > 0) {
          const nft = {
            img: data[0].imageUrl,
            video: video3,
            name: data[0].name,
            value: data[0].tokenId,
          };
          arr.push(nft);
        }

        // const cardData = [
        //   {
        //     image: collectionimg1,

        //     name: "CraftMyrianxx Fusion",
        //     nftid: "NFT ID",
        //     value: "#32E22xx4",
        //   },
        // ];
        // Perform any other operations with the data here
        setCardData(arr);
      }
    } catch (error) {
      console.error("Error fetching user assets:", error);
    }
  };
  useEffect(() => {
    prepareNftData();
  }, [userAddress]);
  const getAvailableNfts = async () => {
    try {
      const mClient = {
        networkId: Network.MAINNET,
        provider: myriaClient?.web3Instance.currentProvider,
        web3: myriaClient?.web3Instance,
        env: EnvTypes.PRODUCTION,
      };

      const client = new MyriaClient(mClient);
      const onchainAssetManager = new OnchainAssetManager(client);

      const starkKey = environment.OWNER_STARK_KEY; // User stark key
      const page = 1;
      const limit = 100;

      const listFilteredNfts = await onchainAssetManager.getAssetByStarkKey(
        starkKey,
        page,
        limit
      );
      let ourNfts = [];
      if (listFilteredNfts) {
        for (let i = 0; i < listFilteredNfts?.data?.items?.length; i++) {
          if (
            listFilteredNfts?.data?.items[i]?.tokenAddress.toLowerCase() ==
              environment.NFT_CONTRACT.toLowerCase() &&
            listFilteredNfts?.data?.items[i]?.status == "MINTED"
          ) {
            ourNfts.push(listFilteredNfts?.data?.items[i]);
          }
        }
      }
      return ourNfts;
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  const getUserCurrentAssets = async () => {
    try {
      const mClient = {
        networkId: Network.MAINNET,
        provider: myriaClient?.web3Instance.currentProvider,
        web3: myriaClient?.web3Instance,
        env: EnvTypes.PRODUCTION,
      };

      const client = new MyriaClient(mClient);
      const onchainAssetManager = new OnchainAssetManager(client);
      const page = 1;
      const limit = 100;
      const listFilteredNfts = await onchainAssetManager.getAssetByStarkKey(
        userStarkKey,
        page,
        limit
      );
      let userNfts = [];
      if (listFilteredNfts) {
        for (let i = 0; i < listFilteredNfts?.data?.items?.length; i++) {
          if (
            listFilteredNfts?.data?.items[i]?.tokenAddress.toLowerCase() ==
            environment.NFT_CONTRACT.toLowerCase()
          ) {
            userNfts.push(listFilteredNfts?.data?.items[i]);
          }
        }
      }
      console.log("USER NFTS", userNfts);
      return userNfts;
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  const mintNft = async () => {
    try {
      setIsLoading(true);
      if (!isWalletConnected) throw "Please connect wallet";
      const userCurrentAssets = await getUserCurrentAssets();
      if (userCurrentAssets && userCurrentAssets?.length > 0)
        throw "You already claimed Nft";
      const remainingNfts = await getAvailableNfts();
      if (remainingNfts && remainingNfts?.length == 0)
        throw "All nfts are claimed";

      const YOUR_NFT_CONTRACT_ADDRESS = environment.NFT_CONTRACT; // Your NFTs smart contract
      const RECEIVER_WALLET_ADDRESS = userAddress; // Your receiver/users wallet address

      // const transferredItems = [
      //   {
      //     quantizedAmount: "1", // Should be 1 as always
      //     receiverWalletAddress: RECEIVER_WALLET_ADDRESS,
      //     tokenType: TokenType.MINTABLE_ERC721,
      //     tokenData: {
      //       tokenAddress: YOUR_NFT_CONTRACT_ADDRESS,
      //       tokenId: remainingNfts[0].tokenId, // Your minted token ID
      //     },
      //   },
      // ];
      // const transferTokenParams = {
      //   senderWalletAddress: SENDER_WALLET_ADDRESS,
      //   groupRequestId:uuidv4(),
      //   requestId: uuidv4(),
      //   partnerRefId:environment.PROJECT_ID,
      //   description: "NFTS",
      //   items: transferredItems,
      // };
      const mClient = {
        networkId: Network.MAINNET,
        provider: myriaClient?.web3Instance.currentProvider,
        web3: myriaClient?.web3Instance,
        env: EnvTypes.PRODUCTION,
      };
      const SENDER_WALLET_ADDRESS = environment.SENDER_ADDRESS;
      const moduleFactory = ModuleFactory.getInstance(mClient);
      const transactionManager = moduleFactory.getTransactionManager();

      const transferTokenParams = {
        senderWalletAddress: SENDER_WALLET_ADDRESS,
        senderPublicKey: environment.OWNER_STARK_KEY,
        receiverPublicKey: userStarkKey,
        tokenAddress: environment.NFT_CONTRACT,
        tokenId: remainingNfts[0].tokenId,
        quantizedAmount: "1",
        groupRequestId: uuidv4(),
        partnerRefId: environment.PROJECT_ID,
        myriaPrivateKey: environment.MYRIA_PRIVATE_KEY,
      };
      const transferResult = await transactionManager.transferERC721Token(
        transferTokenParams
      );
      console.log("Transaction result -> ", transferResult);
      if (transferResult?.status == "MINTED") {
        await prepareNftData();
        setIsLoading(false);

        ToastMessage("Success!", "Transaction successful", "success");
      }
    } catch (error) {
      console.log("mintNft error", error);
      setIsLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
      // ToastMessage("Error", "Transaction failed", "error");
    }
  };

  const [open, setOpen] = useState(false);
  const [mintModal, setMintModal] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const showMintModal = () => {
    setMintModal(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setMintModal(false);
  };

  return (
    <MainSection>
      <Modal open={open} centered onCancel={handleCancel}>
        <MessageModal
          handleCancel={handleCancel}
          message="Minting is currently available on desktop only. Please kindly switch
            to your desktop browser. Stay tuned for mobile minting updates!"
        />
      </Modal>

      <Modal open={mintModal} centered onCancel={handleCancel}>
        <MintNftModal handleCancel={handleCancel} mintNft={mintNft} />
      </Modal>
      {isLoading && <Loading>{"Minting in progress"}</Loading>}
      <MainContainer fluid>
        <MainWrapper>
          <MainRow>
            <MainCol lg={7}>
              <div className="title">
                <Title>
                  Mint & Dive into <br />
                  <span>RetroCraft's</span> NFT
                  <br /> GameFi Adventure!
                </Title>
              </div>

              <div className="leftdiv">
                <ImgWrapper>
                  <Image src={heroimg} />
                </ImgWrapper>
                {/* {cardData.map((item, index) => (
                  <MainCol lg={3}>
                    <CardWrapper2>
                      <Image className="innerimg" src={collectioncardimg} />
                      <CardInnerDiv2>
                        <div className="innerimg2">
                          <video src={item.video} autoPlay muted loop />
                        </div>

                        <TextDiv2>
                          <div>
                            <Name>{item.name}</Name>
                          </div>
                          <div className="text-div">
                            <NftId>{item.nftid}</NftId>
                            <Nftvalue>{item.value}</Nftvalue>
                          </div>
                        </TextDiv2>
                      </CardInnerDiv2>
                    </CardWrapper2>
                  </MainCol>
                ))} */}
              </div>

              <Text>
                Now Available: Free Mint in celebration of RetroCraft x Myria!
                <br />
                First come, first served. 1 NFT per wallet.
              </Text>
              <Mintbtn
                onClick={() => {
                  showMintModal();
                  //mintNft();
                }}
                className="mint-btn"
              >
                Mint NFT
              </Mintbtn>
              <Mintbtn className="mobile-mint-btn" onClick={showModal}>
                Mint NFT
              </Mintbtn>
            </MainCol>
            {/* <MainCol lg={1}></MainCol> */}
            <MainCol lg={5} className="rightdiv">
              <ImgWrapper>
                <Image src={heroimg} />
              </ImgWrapper>
            </MainCol>
            {/* {cardData.map((item, index) => (
              <MainCol lg={3} className="rightdiv">
                <CardWrapper2>
                  <Image className="innerimg" src={collectioncardimg} />
                  <CardInnerDiv2>
                    <div className="innerimg2">
                      <video src={item.video} autoPlay muted loop />
                    </div>

                    <TextDiv2>
                      <div>
                        <Name>{item.name}</Name>
                      </div>
                      <div className="text-div">
                        <NftId>{item.nftid}</NftId>
                        <Nftvalue>{item.value}</Nftvalue>
                      </div>
                    </TextDiv2>
                  </CardInnerDiv2>
                </CardWrapper2>
              </MainCol>
            ))} */}
          </MainRow>
        </MainWrapper>
      </MainContainer>
    </MainSection>
  );
};

export default HeroComp;
