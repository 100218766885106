import styled from "styled-components";

export const MainModel = styled.div``;

export const ModelsData = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 42.8125rem;
  min-height: 15.9375rem;
  border-radius: 0.48338rem;
  border: 0.5px solid var(--yellow, #e0c528);
  background: #000;
  box-shadow: 0px 4px 15.5px 0px rgba(247, 193, 58, 0.18);
  text-align: center;

  @media (max-width: 767px) {
    width: 370px;
    padding: 0rem 1rem;
  }

  .btn-div {
    position: relative;
    margin-top: 2rem;
    cursor: pointer;
  }
  .text-div {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-dev {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }

  .icon {
    display: flex;
    justify-content: end;
    align-items: end;
    padding-right: 0.5rem;
    padding-top: 0.5rem;

    @media (max-width: 767px) {
      padding-right: 0rem;
    }
  }

  @media (max-width: 767px) {
    .btn-img {
      width: 20rem;
    }
  }

  .imgs {
    width: 3.83475rem;
    height: 3.52169rem;
    object-fit: contain;

    @media (max-width: 767px) {
      width: 2.83475rem;
      height: 2.52169rem;
    }
  }

  .metamask-div {
    display: flex;
    gap: 8rem;
    align-items: center;

    @media (max-width: 767px) {
      gap: 3rem;
    }
  }

  .metamask-div2 {
    display: flex;
    gap: 4rem;
    align-items: center;

    @media (max-width: 767px) {
      gap: 1rem;
    }
  }
`;

export const ConnectButton = styled.button`
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  border-radius: 30px;
  width: 360px;
  height: 110px;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  border: none;
  font-weight: 700;
  font-size: 19px;
  @media (max-width: 415px) {
    width: 300px;
  }
`;

export const ConnectIcon = styled.img`
  width: 60px;
  margin-right: 1rem;
`;

export const Text = styled.p`
  font-family: "Inter";
  font-size: 2.26231rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3rem;
  color: #fff;
  margin-bottom: 0rem;

  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
`;

export const MainDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  border: 1.186px solid rgba(17, 17, 17, 0.5);
  width: 460.352px;
  height: 80.68px;
  cursor: pointer;
  padding: 0rem 2rem;

  @media (max-width: 767px) {
    width: 100%;
  }
  .imgs {
    width: 48.645px;
    height: 45.086px;
    object-fit: contain;
  }

  h5 {
    color: rgba(0, 0, 0, 0.6);
    font-size: 33.601px;
    font-style: normal;
    font-weight: 700;
    line-height: 47.041px; /* 140% */
    letter-spacing: -0.521px;
    text-transform: uppercase;
    margin-bottom: 0rem;

    @media (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  &:hover {
    background: #111;

    h5 {
      color: #ffbe53;
    }
  }
`;

export const Title = styled.h5`
  color: #fff;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 4.82794rem; /* 193.117% */
  letter-spacing: -0.05rem;
  text-transform: uppercase;

  span {
    background: var(
      --yellow,
      linear-gradient(180deg, #e0c528 0%, #f7c13a 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 4.82794rem;
    letter-spacing: -0.05rem;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

export const CancelBtn = styled.button`
  font-family: "Inter";
  font-size: 20.168px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(--yellow, linear-gradient(180deg, #e0c528 0%, #f7c13a 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 5.5px;
  border: 1.833px solid var(--yellow, #e0c528);
  width: 183.347px;
  height: 54.087px;
  margin-top: 1rem;
  &:hover {
    background: #ffbe53;
    -webkit-text-fill-color: #000;
    border: none;
  }
`;
export const MintBtn = styled.button`
  font-family: "Inter";
  font-size: 20.168px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(--yellow, linear-gradient(180deg, #e0c528 0%, #f7c13a 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 5.5px;
  border: 1.833px solid var(--yellow, #e0c528);
  width: 183.347px;
  height: 54.087px;
  margin-top: 1rem;
  &:hover {
    background: #ffbe53;
    -webkit-text-fill-color: #000;
    border: none;
  }
`;
