import WalletConnectProvider from "@walletconnect/web3-provider";
import { useState } from "react";
import { toast } from "react-toastify";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import {
  EnvTypes,
  MyriaClient,
  Networks,
  UserDataResponse,
  UserType,
  WalletManager,
} from "myria-core-sdk";
import { getMyriaClient } from "../samples/common/myria-client";
import { getModuleFactory, signMetamask } from "./myriaCodeSdk";
import { ToastMessage } from "components/common";
import environment from "environment";
export const changeNetwork = async (provider, ENV_CHAIN_ID) => {
  const chainId = ENV_CHAIN_ID;
  const changeIdHex = "0x" + chainId.toString(16);
  try {
    await provider?.request({
      method: "wallet_switchEthereumChain",
      params: [
        {
          chainId: changeIdHex,
        },
      ],
    });
  } catch (err) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (err.code === 4902) {
      await provider?.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: changeIdHex,
          },
        ],
      });
    } else {
      throw err;
    }
  }
};

export const NetworkID = {
  MAINNET: Networks.MAINNET,
  GOERLI: Networks.GOERLI,
  SEPOLIA: Networks.SEPOLIA,
};

const useMetamask = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [walletAdrress, setWalletAddress] = useState("0x");
  const [starkKey, setStarkKey] = useState("0x");
  const [myriaClient, setMyriaClient] = useState(null);
  const [netWorkIdUser, setNetWorkIdByUser] = useState(NetworkID.MAINNET);
  const [loginWallet, setLoginWallet] = useState(null);
  const checkIfMetaMaskInstalled = () => {
    const { ethereum } = window;
    if (Boolean(ethereum && ethereum.isMetaMask)) {
      return true;
    } else {
      return false;
    }
  };

  const loginL2Wallet = async (metamaskAccount) => {
    const moduleFactory = await getModuleFactory();

    if (!moduleFactory) return;
    const userModule = moduleFactory.getUserManager();
    try {
      const user = await userModule.getUserByWalletAddress(metamaskAccount);
      if (
        user &&
        user?.ethAddress?.toLowerCase() === metamaskAccount?.toLowerCase()
      ) {
        return user;
      } else {
        console.log("META MASK CLOSED");
        return undefined;
      }
    } catch {
      console.log("metamaskAccount", metamaskAccount);
      const walletManager = new WalletManager(EnvTypes.PRODUCTION);
      const data = await walletManager.connectAndLogin();
      console.log("Production Data ->", data);
      return data;
      // ToastMessage(
      //   "Error",
      //   "Login Failed, This Account is not existed. Please register on the Myria website.",
      //   "error"
      // );

      // console.log("Wallet not registered");
      // return undefined;
    }
  };

  const connectL2Wallet = async () => {
    try {
      const tWebModal = new Web3Modal({
        network: environment.NETWORK,
        cacheProvider: true,
        providerOptions: getProviderOptions(),
      });
      console.log("45454545");
      const provider = await tWebModal.connect();
      const providerApi = new ethers.providers.Web3Provider(provider);
      const network = await providerApi.getNetwork();
      if (network.chainId !== netWorkIdUser) {
        await changeNetwork(provider, netWorkIdUser);
      }
      if (checkIfMetaMaskInstalled()) {
        const accounts = await signMetamask();

        if (!accounts || accounts.length === 0) return;
        const currentAccount = accounts[0];

        const moduleFactory = await getModuleFactory();

        if (!moduleFactory) return;

        const commonModule = moduleFactory.getCommonModule();
        console.log("sdsdsdsdsdsd555555555558487878555s", currentAccount);

        const getStarkKey = await commonModule.generateStarkKey(currentAccount);
        console.log("sdsdsdsdsdsd5555555555s", currentAccount);

        const statusLoginL2Wallet = await loginL2Wallet(currentAccount);

        if (statusLoginL2Wallet === undefined) return;
        if (
          statusLoginL2Wallet &&
          "0x" + getStarkKey === statusLoginL2Wallet.starkKey
        ) {
          ToastMessage("Success!", "Login Success!", "success");

          setIsConnected(true);
          setLoginWallet(statusLoginL2Wallet);
          const sampleClient = await getMyriaClient(true);
          setWalletAddress(statusLoginL2Wallet.ethAddress);
          setStarkKey(statusLoginL2Wallet.starkKey);
          setMyriaClient(sampleClient);
        } else {
          ToastMessage(
            "Error",
            "Login failed, The stark key is mismatch. Please switch with correct wallet.",
            "error"
          );

          // disconnectL2Wallet();
        }
      } else {
        ToastMessage(
          "Error",
          "Please install Metamask before connect wallet",
          "error"
        );
      }
    } catch (error) {
      console.log("error12115", error);
    }
  };
  const getProviderOptions = function () {
    return {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: "2b2b802ce8414591a6c76a30cf192ad3",
        },
      },
    };
  };

  const disconnectL2Wallet = () => {
    setIsConnected(false);
    setWalletAddress("0x");
    setStarkKey("0x");
  };

  return {
    connectL2Wallet,
    isConnected,
    walletAdrress,
    starkKey,
    myriaClient,
    netWorkIdUser,
    setNetWorkIdByUser,
  };
};

export default useMetamask;
