import styled from "styled-components";

export const MainSection = styled.section`
  border-top: 0.5px solid rgb(224 197 40 / 50%);

  .text {
    color: #a7a7a8;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin-bottom: 0rem;
    margin-top: 0.2rem;
  }
`;

export const MainWrapper = styled.div`
  margin-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }

  .logo-img {
    width: 306px;
    height: 81.327px;
    object-fit: contain;

    @media (max-width: 767px) {
      width: 11.875rem;
      height: 2.964rem;
      object-fit: contain;
    }
  }
`;

export const Text = styled.h5`
  color: #fff;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 41.253px; /* 229.183% */
  letter-spacing: -0.458px;
  margin-bottom: 0rem;
  @media (max-width: 767px) {
    margin-top: 1rem;
    color: #fff;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.57831rem; /* 412.53% */
    letter-spacing: -0.02863rem;
  }
`;

export const SocialDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  .social-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    cursor: pointer;

    svg {
      color: white;
      font-size: 24px;
      transition: 300ms ease-in-out all;
    }
  }

  .social-wrapper:hover svg {
    scale: 1.35;
    color: #ffbe53;
    transition: 300ms ease-in-out all;
  }

  img {
    cursor: pointer;
  }

  /* .imgs {
    margin-top: 1rem;
  } */
  @media (max-width: 767px) {
    margin-top: 1rem;
  }

  @media (max-width: 990px) {
    display: none;
  }
`;

export const MobileSocialDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  img {
    cursor: pointer;
  }

  .imgs {
    margin-top: 1rem;
  }

  @media (min-width: 990px) {
    display: none;
  }
  @media (max-width: 990px) {
    margin-top: 1rem;
  }
`;
