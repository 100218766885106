import { HomeCom } from "../components";

const Home = ({
  setUserAddress,
  userAddress,
  setUserStarkKey,
  userStarkKey,
  setIsWalletConnected,
  isWalletConnected,
  setClient,
}) => {
  return (
    <div>
      <HomeCom
        setUserAddress={setUserAddress}
        userAddress={userAddress}
        setUserStarkKey={setUserStarkKey}
        userStarkKey={userStarkKey}
        setIsWalletConnected={setIsWalletConnected}
        isWalletConnected={isWalletConnected}
        setClient={setClient}
      />
    </div>
  );
};

export default Home;
