import styled, { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-family: 'Readex Pro', sans-serif;

  } 
 body
{
  overflow-x: hidden !important;
  font-family: 'Inter', sans-serif;
  font-family: 'Readex Pro', sans-serif;
  background: #000;

margin: 0;
  padding: 0;
}


iframe
{
  position: absolute !important;
  width: none !important;
  top: none !important;
  left: none !important;
  right: none !important;
  z-index: -5 !important;
}

/* Loader */
.cover-spin {
 position: fixed;
 width: 100%;
 left: 0;
 right: 0;
 top: 0;
 bottom: 0;
 background:rgba(0,0,0,0.4);
 z-index: 999999;
 display: flex;
 justify-content: center;
 align-items: center;
}


 
/* Models styles  */
.ant-modal-footer
  {
    display: none;
  }
  .ant-modal-close
  {
    display: none;
  }
  .ant-modal-content
  {
   background: none;
  }
  .ant-modal-body
  {
    padding: 0px;
  }
  .ant-modal-mask
  {
    background-color: rgb(0 0 0 / 78%) ;
  }
  .closeModelInput
  {
    transform: translateY(-2px);
  }
    .ant-space-align-baseline
  {
    display: flex;
    justify-content:center  ;
  }
  .modalInputMale
  {
    border-top-right-radius:7px;
  border-bottom-right-radius:7px;
  transform: translateX(-6px);
  }
  .ant-form-vertical .ant-form-item-label>label {
  
 
    font-weight:600;
}

  /* Models styles */

  .container-fluid {
  
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem; 
  padding-right: 1rem; 

  @media (max-width: 1600px) {
      padding-right: 7rem;
      padding-left: 7rem;
    }
    @media (max-width: 900px) {
      padding-left: 1rem; 
  padding-right: 1rem; 
    }
    /* @media (max-width: 767.98px) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
    @media (max-width: 575.98px) {
      padding-right: 10px;
      padding-left: 10px;
    } */
  }

   
  a 
  {
    text-decoration: none !important;
  }
 
`;

export default GlobalStyle;
