import {
  BtnWrapper,
  CancelBtn,
  MainModel,
  MintBtn,
  ModelsData,
  Text,
  Title,
} from "./elements";

import { IoMdClose } from "react-icons/io";
const MintNftModal = ({ handleCancel, mintNft }) => {
  return (
    <MainModel>
      <ModelsData>
        <div className="icon" onClick={handleCancel}>
          <IoMdClose color="#E1C529" fontSize={20} cursor="pointer" />
        </div>
        <Title>Are you sure you want to mint this NFT?</Title>
        <BtnWrapper>
          <CancelBtn onClick={handleCancel}>Cancel</CancelBtn>
          <MintBtn onClick={mintNft}>Mint</MintBtn>
        </BtnWrapper>
      </ModelsData>
    </MainModel>
  );
};

export default MintNftModal;
