import React, { useState } from "react";
import { ConnectBtn, DrawerDiv, InnerDrawer } from "./elements";
import { logo1 } from "assets";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import MessageModal from "components/common/modal/messageModal";

function Drawer({ handleModelOpen, setShowDrawer }) {
  const [open, setOpen] = useState(false);
  const [nftopen, setnftmodal] = useState(false);

  const showModal = () => {
    setOpen(true);
  };
  const shownftModal = () => {
    setnftmodal(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setnftmodal(false);
  };

  const handleClick = () => {
    window.open("https://retrocraft.io/", "_blank");
  };
  return (
    <>
      <Modal open={open} centered onCancel={handleCancel}>
        <MessageModal
          handleCancel={handleCancel}
          message="Wallet connection is currently available on desktop only. Please
            kindly switch to your desktop browser."
        />
      </Modal>
      <Modal open={nftopen} centered onCancel={handleCancel}>
        <MessageModal
          handleCancel={handleCancel}
          message="Minting is currently available on desktop only. Please kindly switch
          to your desktop browser. Stay tuned for mobile minting updates!"
        />
      </Modal>
      <DrawerDiv
        onClick={() => setShowDrawer(false)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key={"drawer"}
        transition={{ delay: 0.1, duration: 0.2 }}
      >
        <InnerDrawer
          onClick={(e) => e.stopPropagation()}
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          transition={{ delay: 0.1, duration: 0.2 }}
        >
          <svg
            className="close"
            onClick={() => setShowDrawer(false)}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M2.0056 11.5249L0.922729 10.442L5.25423 6.11054L0.922729 1.77904L2.0056 0.696167L6.3371 5.02767L10.6686 0.696167L11.7515 1.77904L7.41998 6.11054L11.7515 10.442L10.6686 11.5249L6.3371 7.19342L2.0056 11.5249Z"
              fill="#E1C529"
            />
          </svg>
          <div className="top-logo-div">
            <img className="" src={logo1} />
          </div>

          <div className="bottom-div">
            <Link
              to=""
              onClick={() => {
                setShowDrawer(false);
                handleClick();
              }}
            >
              <h4>Home</h4>
            </Link>

            <a href="#collection" onClick={() => setShowDrawer(false)}>
              <h4>Collections</h4>
            </a>
            <Link to="" onClick={shownftModal}>
              <h4>My NFTs</h4>
            </Link>
            <ConnectBtn onClick={showModal}>Connect Wallet</ConnectBtn>
          </div>
        </InnerDrawer>
      </DrawerDiv>
    </>
  );
}

export default Drawer;
