import React from "react";
import {
  MainSection,
  MainWrapper,
  MobileSocialDiv,
  SocialDiv,
  Text,
} from "./elements";
import { MainContainer } from "components/common";
import { Image } from "react-bootstrap";
import {
  logo1,
  mailicons,
  msvg,
  telegramicon,
  telegramicons1,
  telegramicons2,
  tsvg1,
  tsvg2,
  twittericon,
  twittericons,
  twsvg,
  youtubeicons,
  ysvg,
} from "assets";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { FiYoutube, FiMail } from "react-icons/fi";
import { motion } from "framer-motion";

const FooterComp = () => {
  const images = [
    { src: FaXTwitter, alt: "Twitter" },
    { src: FaTelegramPlane, alt: "Telegram 1" },
    { src: telegramicons2, alt: "Telegram 2" },
    { src: FiYoutube, alt: "YouTube" },
    { src: FiMail, alt: "Mail" },
  ];

  const handleTwitterClick = () => {
    window.open("https://twitter.com/RetroCraftio", "_blank");
  };
  const handleTelegramgroupClick = () => {
    window.open("https://t.me/RetroCraftglobal", "_blank");
  };
  const handleTelegramchannelClick = () => {
    window.open("https://t.me/retrocraftio", "_blank");
  };
  const handleyoutubeClick = () => {
    window.open("https://www.youtube.com/@RetroCraft-global", "_blank");
  };

  return (
    <MainSection>
      <MainContainer fluid>
        <MainWrapper>
          <div>
            <Image src={logo1} className="logo-img" />
          </div>
          <MobileSocialDiv>
            <Image src={twsvg} onClick={handleTwitterClick} />
            <div
              style={{ marginTop: "1rem" }}
              onClick={handleTelegramgroupClick}
            >
              <Image src={tsvg1} />
              <p className="text">Group</p>
            </div>
            <div
              style={{ marginTop: "1rem" }}
              onClick={handleTelegramchannelClick}
            >
              <Image src={tsvg2} />
              <p className="text">Channel</p>
            </div>
            <Image src={ysvg} onClick={handleyoutubeClick} />
            <a href="mailto:admin@abc.com">
              <Image src={msvg} />
            </a>
          </MobileSocialDiv>
          <Text>2023 by RETROCRAFT. All Rights Reserved.</Text>
          <SocialDiv>
            <motion.div
              className="social-wrapper"
              initial={{ border: "2px solid white" }}
              whileHover={{ border: "2px solid transparent" }}
              transition={{ delay: 0.1, ease: "easeOut" }}
              onClick={handleTwitterClick}
            >
              <FaXTwitter />
            </motion.div>
            <div
              style={{ marginTop: ".8rem" }}
              onClick={handleTelegramgroupClick}
            >
              <motion.div
                className="social-wrapper"
                initial={{ border: "2px solid white" }}
                whileHover={{ border: "2px solid transparent" }}
                transition={{ delay: 0.1, ease: "easeOut" }}
              >
                <FaTelegramPlane />
              </motion.div>
              <p className="text">Group</p>
            </div>
            <div
              style={{ marginTop: ".8rem" }}
              onClick={handleTelegramchannelClick}
            >
              <motion.div
                className="social-wrapper"
                initial={{ border: "2px solid white" }}
                whileHover={{ border: "2px solid transparent" }}
                transition={{ delay: 0.1, ease: "easeOut" }}
              >
                <FaTelegramPlane />
              </motion.div>

              <p className="text">Channel</p>
            </div>
            <motion.div
              className="social-wrapper"
              initial={{ border: "2px solid white" }}
              whileHover={{ border: "2px solid transparent" }}
              transition={{ delay: 0.1, ease: "easeOut" }}
              onClick={handleyoutubeClick}
            >
              <FiYoutube />
            </motion.div>
            <motion.div
              className="social-wrapper"
              initial={{ border: "2px solid white" }}
              whileHover={{ border: "2px solid transparent" }}
              transition={{ delay: 0.1, ease: "easeOut" }}
            >
              <a href="mailto:Team@retrocraft.io">
                <FiMail />
              </a>
            </motion.div>
          </SocialDiv>
        </MainWrapper>
      </MainContainer>
    </MainSection>
  );
};

export default FooterComp;
