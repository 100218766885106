import { EnvTypes, ModuleFactory, MyriaClient } from 'myria-core-sdk';
import Web3 from 'web3';
import { NetworkID } from './useMetamask';

const MAINNET = 1;

async function signMetamask() {
  const accountSignMetamask = await window.ethereum.request({ method: 'eth_requestAccounts' });
  console.log('MetaMask is installed!');
  return accountSignMetamask;
}

function getEnvTypes(networkId) {
  if (networkId === NetworkID.MAINNET) {
    return EnvTypes.PRODUCTION;
  } else if (networkId === NetworkID.SEPOLIA) {
    return EnvTypes.STAGING; 
  }
  return EnvTypes.STAGING;
}

async function initialWeb3() {
  return new Web3(Web3.givenProvider);
}

async function getNetworkType() {
  const web3 = await initialWeb3();
  return await web3.eth.net.getNetworkType();
}

async function getAccounts() {
  const web3 = await initialWeb3();
  return await web3.eth.getAccounts();
}

async function getNetworkId() {
  let windowBrowser;
  if (window && window.ethereum) {
    windowBrowser = await initialWeb3();
    window.web3 = windowBrowser;
  } else {
    return 0;
  }
  await signMetamask();

  const networkId = await windowBrowser.eth.net.getId();
  return networkId;
}

async function getModuleFactory() {
  let windowBrowser;
  let networkId;
  if (window && window.ethereum) {
    windowBrowser = await initialWeb3();
    window.web3 = windowBrowser;
  } else {
    windowBrowser = window.web3;
  }
  await signMetamask();
  try {
    networkId = await windowBrowser.eth.net.getId();
  } catch (error) {
    console.log(error);
  }
  const client = {
    provider: windowBrowser.eth.currentProvider,
    networkId: networkId ? networkId : MAINNET,
    web3: windowBrowser,
    env: getEnvTypes(networkId),
  };

  const myriaClient = new MyriaClient(client);
  const moduleFactory = ModuleFactory.getInstance(myriaClient);
  return moduleFactory;
}

export { getModuleFactory, getAccounts, getNetworkType, initialWeb3, getNetworkId, signMetamask };
