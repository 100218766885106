import { MainModel, ModelsData, Text, Title } from "./elements";

import { IoMdClose } from "react-icons/io";
const MessageModal = ({ handleCancel, message }) => {
  return (
    <MainModel>
      <ModelsData>
        <div className="icon" onClick={handleCancel}>
          <IoMdClose color="#E1C529" fontSize={20} cursor="pointer" />
        </div>
        <Title>
          <span>Error</span>
        </Title>

        <Text>{message}</Text>
      </ModelsData>
    </MainModel>
  );
};

export default MessageModal;
