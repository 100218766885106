import React, { useEffect, useState } from "react";
import {
  ConnectBtn,
  MainDev,
  MainSection,
  MainWrapper,
  Text,
} from "./elements";
import { Image } from "react-bootstrap";
import { logo1 } from "../../../assets";
import { MainContainer } from "../../common/mainStyle/mainStyle";
import { FaWallet } from "react-icons/fa";
import { MainModel } from "components/common";
import { mainModel } from "store/redux/slices/helperSlices/modelSlice";
import { useAppDispatch } from "store/store";
import { useWalletConnectClient } from "services/walletServices";
import { CommonUtility } from "utility/common";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { RiMenu3Fill } from "react-icons/ri";
import Drawer from "./drawer";
import { AnimatePresence } from "framer-motion";
import useMetamask from "helpers/useMetamask";
import { Link, useNavigate } from "react-router-dom";

const NavbarComp = ({
  setUserAddress,
  setIsWalletConnected,
  setUserStarkKey,
  userAddress,
  isWalletConnected,
  setClient,
}) => {
  console.log("IMPORTANT ", userAddress, isWalletConnected);
  const {
    isConnected,
    starkKey,
    connectL2Wallet,
    walletAdrress,
    myriaClient,
    netWorkIdUser,
    setNetWorkIdByUser,
  } = useMetamask();
  // setUserAddress(walletAdrress);
  // setIsWalletConnected(isConnected);
  // setUserStarkKey(starkKey);
  useEffect(() => {
    if (walletAdrress && isConnected && starkKey) {
      setUserAddress(walletAdrress);
      setIsWalletConnected(isConnected);
      setUserStarkKey(starkKey);
      setClient(myriaClient);
    }
  }, [walletAdrress, isConnected, starkKey]);
  const [connectModel, setConnectModel] = useState(false);

  const dispatch = useAppDispatch();
  const handleModelOpen = () => {
    setConnectModel(true);
    dispatch(mainModel(true));
  };

  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    if (showDrawer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [showDrawer]);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClick = () => {
    window.open("https://retrocraft.io/", "_blank");
  };

  return (
    <MainSection>
      <MainModel connectModel={connectModel} />
      <Navbar expand="lg">
        <MainContainer fluid>
          <Navbar.Brand href="/">
            <Image src={logo1} className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggle" />
          <Navbar.Collapse id="basic-navbar-nav" className="toggle">
            <Nav className="m-auto">
              <Link to="" onClick={handleClick}>
                Home
              </Link>
              <Nav.Link onClick={() => scrollToSection("collection")}>
                Collections
              </Nav.Link>
              <Link to="/mynft">My NFTs</Link>
            </Nav>
            <ConnectBtn onClick={async () => await connectL2Wallet()}>
              {isConnected || isWalletConnected
                ? `${userAddress.slice(0, 5)}...${userAddress.slice(38, 42)}`
                : "Connect Wallet"}
            </ConnectBtn>
          </Navbar.Collapse>
          <div className="menu">
            <svg
              onClick={() => setShowDrawer(true)}
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="28"
              viewBox="0 0 36 28"
              fill="none"
            >
              <g clip-path="url(#clip0_0_244)">
                <path
                  d="M25.7289 12.4229C28.8635 12.4229 31.9985 12.4229 35.134 12.4229C35.6332 12.4229 36.0062 12.7254 36.2173 13.3233C36.4318 13.9308 36.3623 14.5182 36.0367 15.0314C35.8078 15.3929 35.502 15.566 35.1485 15.566C32.7895 15.566 30.4304 15.566 28.071 15.566C24.1819 15.566 20.2926 15.568 16.403 15.5719C16.03 15.5719 15.6981 15.4662 15.4279 15.1022C15.097 14.6543 14.9929 13.9751 15.1681 13.4079C15.2549 13.1156 15.4057 12.8652 15.6006 12.6898C15.7956 12.5143 16.0257 12.422 16.2605 12.425C17.8333 12.4224 19.4061 12.4224 20.9788 12.425L25.7289 12.4229Z"
                  fill="url(#paint0_linear_0_244)"
                />
                <path
                  d="M22.1701 24.997C18.0391 24.997 13.908 24.998 9.77678 24.9999C9.32965 24.9999 8.91692 24.9294 8.56375 24.6333C8.31654 24.4275 8.13874 24.1505 8.05456 23.84C7.97037 23.5296 7.98388 23.2007 8.09325 22.8982C8.20261 22.5956 8.40252 22.3342 8.66578 22.1493C8.92904 21.9644 9.24286 21.8652 9.56454 21.865H34.6892C35.8918 21.865 36.6665 22.9845 36.1942 24.0578C35.9396 24.6367 35.4744 24.9806 34.8377 24.9857C33.1532 25.005 31.4688 24.9957 29.7843 24.9966L22.1701 24.997Z"
                  fill="url(#paint1_linear_0_244)"
                />
                <path
                  d="M22.1491 2.97632H34.7517C35.622 2.97632 36.3187 3.64743 36.3267 4.49135C36.3351 5.3877 35.6698 6.09237 34.7747 6.09699C32.706 6.10747 30.6373 6.10118 28.567 6.1016H14.2664C12.7078 6.1016 11.1491 6.1016 9.59047 6.09866C8.62575 6.09573 7.9215 5.31514 8.01168 4.35671C8.08551 3.57445 8.75159 2.978 9.56699 2.97716C11.6779 2.97408 13.7891 2.97408 15.9006 2.97716L22.1491 2.97632Z"
                  fill="url(#paint2_linear_0_244)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_0_244"
                  x1="25.7122"
                  y1="12.4229"
                  x2="25.7122"
                  y2="15.5719"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#E0C528" />
                  <stop offset="1" stop-color="#F7C13A" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_0_244"
                  x1="22.1673"
                  y1="21.865"
                  x2="22.1673"
                  y2="24.9999"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#E0C528" />
                  <stop offset="1" stop-color="#F7C13A" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_0_244"
                  x1="22.1653"
                  y1="2.97485"
                  x2="22.1653"
                  y2="6.10309"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#E0C528" />
                  <stop offset="1" stop-color="#F7C13A" />
                </linearGradient>
                <clipPath id="clip0_0_244">
                  <rect width="36" height="27.9758" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </MainContainer>

        <AnimatePresence>
          {showDrawer && (
            <Drawer
              handleModelOpen={handleModelOpen}
              setShowDrawer={setShowDrawer}
            />
          )}
        </AnimatePresence>
      </Navbar>
    </MainSection>
  );
};

export default NavbarComp;
