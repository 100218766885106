import styled from "styled-components";
import { collectionbgimg1 } from "assets";

export const MainSection = styled.section`
  @media (max-width: 990px) {
    .carddiv {
      display: none;
    }
  }

  @media (min-width: 990px) {
    .mobile-carddiv {
      display: none;
    }
  }
`;

export const TextBoxDiv = styled.div`
  margin-top: 2rem;
  position: relative;

  width: 37.5625rem;
  height: 5.5rem;

  @media (max-width: 1600px) {
    width: 25.5625rem;
  }

  @media (max-width: 600px) {
    width: 300px;
  }

  img {
    width: 100% !important;
    height: 100% !important;
  }

  div {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 3rem;
    p {
      margin: 0;
      color: #fff;
      font-size: 3.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 4.82794rem; /* 154.494% */
      letter-spacing: -0.0625rem;

      @media (max-width: 1600px) {
        font-size: 2.125rem;
      }
      @media (max-width: 767px) {
        font-size: 1.74063rem;
      }

      span {
        background: var(
          --yellow,
          linear-gradient(180deg, #e0c528 0%, #f7c13a 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        font-size: 3.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 4.82794rem;
        letter-spacing: -0.0625rem;

        @media (max-width: 1600px) {
          font-size: 2.125rem;
        }
        @media (max-width: 767px) {
          font-size: 1.74063rem;
        }
      }
    }
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 431.058px;
  margin-top: 3rem;
  cursor: pointer;
  @media (min-width: 1602px) {
    height: 471.058px;
  }

  @media (max-width: 1450px) {
    height: 390.058px;
  }

  @media (max-width: 1400px) {
    height: 370.058px;
  }

  .innerimg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
`;

export const MobileCardWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 3rem;
  padding-bottom: 2rem;
  min-height: 400px;

  @media (max-width: 400px) {
    min-height: 350px;
  }
  /* 
  @media (min-width: 1602px) {
    height: 471.058px;
  }

  @media (max-width: 1450px) {
    height: 390.058px;
  }

  @media (max-width: 1400px) {
    height: 370.058px;
  }

  .innerimg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  } */

  .innerimg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;

export const CardInnerDiv = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  .inner-img {
    margin-top: 1.7rem;
    width: 100%;
  }

  .innerimg2 {
    padding: 0rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.7rem;
  }

  video {
    width: 100%;
    margin-top: 1.7rem;
  }
`;

export const TextDiv = styled.div`
  padding: 0 1.5rem;
  margin-top: 1rem;

  .inner-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }

  h2 {
    text-align: start;
    margin: 0;
  }

  .img-div {
    display: flex;
    gap: 0.7rem;
    align-items: center;
  }

  .text-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Name = styled.h5`
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1rem;

  @media (max-width: 1450px) {
    font-size: 14px;
  }
`;

export const LastText = styled.p`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 77.247px; /* 321.862% */
  letter-spacing: 0.96px;
  text-align: end;
`;
