import styled from "styled-components";
import { motion } from "framer-motion";

export const MainSection = styled.section`
  padding-top: 0.3rem;
  .navbar-toggler {
    background-color: #f2bd51 !important;
  }

  .navbar-nav {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  a {
    color: #e4c42b;
    font-family: "Inter";
    font-size: 25.275px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;

    /* @media (max-width: 990px) {
      text-align: center;
    } */
  }

  .nav-link {
    color: #e4c42b;
    font-family: "Inter";
    font-size: 25.275px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;

    /* &:not(:first-child) {
      margin-left: 1.2rem;

      @media (max-width: 990px) {
        margin-left: 0rem;
      }
    } */

    @media (max-width: 990px) {
      text-align: center;
    }
  }

  @media (max-width: 990px) {
    .logo {
      width: 13.42119rem;
      height: 3.35206rem;
    }
  }

  .toggle {
    @media (max-width: 990px) {
      display: none;
    }
  }

  .menu {
    @media (min-width: 990px) {
      display: none;
    }
  }

  .navbar-nav .nav-link.active,
  .navbar-nav .nav-link.show {
    color: #e4c42b !important;
  }
`;

export const ConnectBtn = styled.button`
  color: #000;
  font-family: "Inter";
  font-size: 22.577px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 5.5px;
  background: var(--yellow, linear-gradient(180deg, #e0c528 0%, #f7c13a 100%));
  width: 241px;
  height: 54.218px;
  border: none;

  &:hover {
    border-radius: 0.34375rem;
    background: #9b8400;
    color: #fff;
  }

  @media (max-width: 990px) {
    margin-top: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const DrawerDiv = styled(motion.div)`
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
`;

export const InnerDrawer = styled(motion.div)`
  width: 268px;
  border: 1px solid #000;
  background: #0f0b00;
  height: 100vh;
  border-left: 1px solid #e0c528;
  position: relative;
  padding: 0rem 1rem;

  .close {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  .top-logo-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e0c528;
    margin-top: 3rem;

    img {
      width: 200px;
    }
  }

  .bottom-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;

    h4 {
      color: #e4c42b;
      font-family: "Inter";
      font-size: 25.275px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
  }
`;
