import React from "react";
import { ImgDiv, MainSection, MobileImgDiv, TextBoxDiv } from "./elements";
import { Image } from "react-bootstrap";
import { cardimg, cardimg2, mobilesoonimg, soonimg, textBox } from "assets";
import { MainCol, MainContainer, MainRow } from "components/common";
import { EffectCards, EffectCoverflow, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import {
  CardInnerDiv,
  CardWrapper,
  Name,
  NftId,
  Number,
  TextDiv,
} from "../hero/elements";
const NftStaking = () => {
  return (
    <MainSection>
      <div className="textbox-div">
        <TextBoxDiv>
          <Image className="" src={textBox} />
          <div>
            <p className="inner-text">
              NFT <span>Staking</span>
            </p>
          </div>
        </TextBoxDiv>
      </div>

      <MainContainer fluid>
        <MainRow>
          <MainCol lg={12}>
            <ImgDiv>
              <Image src={soonimg} />
            </ImgDiv>

            <MobileImgDiv>
              <Image src={mobilesoonimg} />
            </MobileImgDiv>
          </MainCol>
        </MainRow>
      </MainContainer>
    </MainSection>
  );
};

export default NftStaking;
