import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home, ProtectedRoute } from "./pages";
import { AdminLogin } from "components/common";
import GlobalStyle from "globalStyles";
import MyNft from "components/mynft";
import { useEffect, useState } from "react";

import {
  DeveloperAccountManager,
  EnvTypes,
  ModuleFactory,
  MyriaClient,
  Network,
  OnchainAssetManager,
  TokenType,
  WalletManager,
} from "myria-core-sdk";
import { video3 } from "assets";
import useMetamask from "helpers/useMetamask";
import environment from "environment";
import FooterComp from "components/homeCom/footer";
function App() {
  const {
    isConnected,
    starkKey,
    connectL2Wallet,
    walletAdrress,
    myriaClient,
    netWorkIdUser,
    setNetWorkIdByUser,
  } = useMetamask();
  const [userAddress, setUserAddress] = useState("");
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [userStarkKey, setUserStarkKey] = useState("");
  const [userNfts, setUserNfts] = useState([]);
  const [Wclient, setClient] = useState(null);
  console.log("CLIENT", Wclient);

  const prepareNftData = async () => {
    try {
      let arr = [];
      if (userAddress) {
        const data = await getUserCurrentAssets();
        if (data && data?.length > 0) {
          const nft = {
            img: data[0].imageUrl,
            video: video3,
            name: data[0].name,
            value: data[0].tokenId,
          };
          arr.push(nft);
        }

        // const cardData = [
        //   {
        //     image: collectionimg1,

        //     name: "CraftMyrianxx Fusion",
        //     nftid: "NFT ID",
        //     value: "#32E22xx4",
        //   },
        // ];
        // Perform any other operations with the data here
        setUserNfts(arr);
      }
    } catch (error) {
      console.error("Error fetching user assets:", error);
    }
  };
  useEffect(() => {
    if (Wclient) prepareNftData();
  }, [userAddress, Wclient]);
  const getUserCurrentAssets = async () => {
    try {
      const mClient = {
        networkId: Network.MAINNET,
        provider: Wclient?.provider,
        web3: Wclient?.web3,
        env: EnvTypes.PRODUCTION,
      };

      const client = new MyriaClient(mClient);
      const onchainAssetManager = new OnchainAssetManager(client);
      const page = 1;
      const limit = 100;
      const listFilteredNfts = await onchainAssetManager.getAssetByStarkKey(
        userStarkKey,
        page,
        limit
      );
      let userNfts = [];
      if (listFilteredNfts) {
        for (let i = 0; i < listFilteredNfts?.data?.items?.length; i++) {
          if (
            listFilteredNfts?.data?.items[i]?.tokenAddress.toLowerCase() ==
            environment.NFT_CONTRACT.toLowerCase()
          ) {
            userNfts.push(listFilteredNfts?.data?.items[i]);
          }
        }
      }
      return userNfts;
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  console.log("USER NFTS123", userNfts);
  return (
    <Router>
      <GlobalStyle />
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <div style={{ flex: 1 }}>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  setUserAddress={setUserAddress}
                  userAddress={userAddress}
                  setUserStarkKey={setUserStarkKey}
                  userStarkKey={userStarkKey}
                  setIsWalletConnected={setIsWalletConnected}
                  isWalletConnected={isWalletConnected}
                  setClient={setClient}
                />
              }
            />
            <Route
              path="/mynft"
              element={
                <MyNft
                  setUserAddress={setUserAddress}
                  userAddress={userAddress}
                  setUserStarkKey={setUserStarkKey}
                  userStarkKey={userStarkKey}
                  setIsWalletConnected={setIsWalletConnected}
                  isWalletConnected={isWalletConnected}
                  userNfts={userNfts}
                  setClient={setClient}
                />
              }
            />

            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/protected-route" element={<ProtectedRoute />} />
            <Route path="/formik-form" element={<ProtectedRoute />} />
          </Routes>
        </div>
        <FooterComp />
      </div>
    </Router>
  );
}

export default App;
