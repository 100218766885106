import styled from "styled-components";
export const MainSection = styled.section`
  margin-top: 6rem;
  padding-bottom: 4rem;

  @media (max-width: 767px) {
    padding-bottom: 0rem;
  }
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2.5rem;

    @media (max-width: 990px) {
      margin-left: 2.3rem;
    }
  }

  @media (max-width: 990px) {
    margin-top: 2rem;
  }

  @media (max-width: 990px) {
    .rightdiv {
      display: none !important;
    }

    .leftdiv {
      margin-top: 3rem;
    }

    .mint-btn {
      display: none;
    }
  }

  @media (min-width: 990px) {
    .leftdiv {
      display: none;
    }

    .mobile-mint-btn {
      display: none;
    }
  }

  @media (max-width: 990px) {
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .rightdiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MainWrapper = styled.div`
  margin-top: 5rem;

  @media (max-width: 990px) {
    margin-top: 2rem;
  }
`;

export const Title = styled.h5`
  color: #fff;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 77.247px; /* 110.353% */
  letter-spacing: -1.4px;
  /* width: 731.553px; */

  span {
    background: var(
      --yellow,
      linear-gradient(180deg, #e0c528 0%, #f7c13a 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 77.247px;
    letter-spacing: -1.4px;

    @media (max-width: 1400px) {
      font-size: 55px;
    }

    @media (max-width: 1320px) {
      font-size: 50px;
    }

    @media (max-width: 990px) {
      color: #fff;
      text-align: center;
      font-size: 2.13625rem;
      font-style: normal;
      font-weight: 600;
      line-height: 3.0625rem; /* 131.086% */
      letter-spacing: -0.04675rem;
    }
  }

  @media (max-width: 1400px) {
    font-size: 55px;
    width: 631.553px;
  }

  @media (max-width: 1320px) {
    font-size: 50px;
    width: 531.553px;
  }

  @media (max-width: 990px) {
    color: #fff;
    text-align: center;
    font-size: 2.23625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.0625rem; /* 131.086% */
    letter-spacing: -0.04675rem;
    width: 23.125rem;
  }
`;

export const Text = styled.p`
  color: #fff;
  font-family: "Inter";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 41.253px; /* 171.887% */
  letter-spacing: -0.458px;
  margin-top: 1.5rem;

  @media (max-width: 990px) {
    color: #fff;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.19281rem; /* 136.324% */
    letter-spacing: -0.01325rem;
    margin-top: 3rem;
  }
`;

export const Mintbtn = styled.button`
  font-family: "Inter";
  font-size: 20.168px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(--yellow, linear-gradient(180deg, #e0c528 0%, #f7c13a 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 5.5px;
  border: 1.833px solid var(--yellow, #e0c528);
  width: 183.347px;
  height: 54.087px;
  margin-top: 1rem;
  &:hover {
    background: #ffbe53;
    -webkit-text-fill-color: #000;
    border: none;
  }

  @media (max-width: 990px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    width: 100%;
  }
`;

export const Name = styled.h5`
  color: #fff;
  font-family: "Inter";
  font-size: 22.789px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 1rem;
`;

export const NftId = styled.h5`
  color: #8e8c8c;
  font-size: 1.21044rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
  margin: 0;
`;

export const Nftvalue = styled.h5`
  font-size: 1.21044rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: var(--yellow, linear-gradient(180deg, #e0c528 0%, #f7c13a 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
`;

export const CardInnerDiv2 = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  .inner-img {
    margin-top: 1.7rem;
    width: 22.66769rem;
    height: 33.44863rem;
  }

  .innerimg2 {
    padding: 0rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  video {
    width: 100%;
    margin-top: 1.7rem;
  }
`;

export const CardWrapper2 = styled.div`
  position: relative;
  width: 100%;
  min-height: 471.058px;
  margin-top: -2rem;
  cursor: pointer;
  /* @media (min-width: 1602px) {
    height: 471.058px;
  }

  @media (max-width: 1450px) {
    height: 390.058px;
  }

  @media (max-width: 1400px) {
    height: 370.058px;
  } */

  .innerimg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
`;

export const TextDiv2 = styled.div`
  padding: 0 1.5rem;
  margin-top: 1rem;

  .inner-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }

  h2 {
    text-align: start;
    margin: 0;
  }

  .img-div {
    display: flex;
    gap: 0.7rem;
    align-items: center;
  }

  .text-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
  }
`;

export const MobileCardWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 3rem;
  padding-bottom: 2rem;
  min-height: 400px;

  @media (max-width: 400px) {
    min-height: 350px;
  }

  .innerimg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;

export const ImgWrapper = styled.div`
  img {
    width: 100%;
  }
`;
