import HeroComp from "./hero";
import MyCollection from "./myCollection";

import FooterComp from "./footer";
import { useEffect, useState } from "react";
import { CommonUtility } from "utility/common";
import { nftAbi } from "utility/contract/NftAbi";
import environment from "environment";
import Web3 from "web3";
import Loading from "components/common/loaders/loading";
import NavbarComp from "./navbar";
import NftStaking from "./nftStaking";
import useMetamask from "helpers/useMetamask";

const HomeCom = ({
  setUserAddress,
  userAddress,
  setUserStarkKey,
  userStarkKey,
  setIsWalletConnected,
  isWalletConnected,
  setClient,
}) => {
  // const [userAddress,setUserAddress]=useState("")
  // const [isWalletConnected,setIsWalletConnected]=useState(false)
  // const [userStarkKey,setUserStarkKey]=useState("")

  // const [allMints, setAllMints] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  // const [latestMints, setLatestMints] = useState([]);
  // const getAllNfts = async () => {
  //   try {
  //     setIsLoading(true);
  //     const web3Provider = new Web3(
  //       environment.PUBLIC_RPC[environment.DEFAULT_CHAIN]
  //     );

  //     const contract = CommonUtility.contract(
  //       web3Provider,
  //       nftAbi,
  //       environment.NFT_CONTRACT[environment.DEFAULT_CHAIN]
  //     );
  //     const currentSupply = await contract.methods.totalSupply().call();
  //     let arr = [];
  //     for (let i = 1; i <= +currentSupply; i++) {
  //       const data = await fetchData(i);
  //       const obj = {
  //         name: data?.name,
  //         nftid: i,
  //         image: data?.image,
  //       };
  //       arr.push(obj);
  //     }
  //     setAllMints(arr);
  //     let latestMintedNfts = [];
  //     if (arr.length > 5) {
  //       for (let j = 0; j <= 4; j++) {
  //         latestMintedNfts.push(arr[j]);
  //       }
  //       setLatestMints(latestMintedNfts);
  //     } else {
  //       setLatestMints(arr);
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.log("error");
  //   }
  // };
  // // Replace the URL with your actual URL

  // const fetchData = async (tokenId) => {
  //   try {
  //     const response = await fetch(`${environment.TOKEN_URI}${tokenId}.json`);
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }
  //     const data = await response.json();
  //     console.log(data);
  //     return data;
  //   } catch (error) {
  //     console.error("Fetch error:", error);
  //   }
  // };
  // useEffect(() => {
  //   getAllNfts();
  // }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <NavbarComp
        setUserAddress={setUserAddress}
        setIsWalletConnected={setIsWalletConnected}
        setUserStarkKey={setUserStarkKey}
        userAddress={userAddress}
        isWalletConnected={isWalletConnected}
        setClient={setClient}
      />
      <HeroComp
        userAddress={userAddress}
        isWalletConnected={isWalletConnected}
        userStarkKey={userStarkKey}
      />
      <MyCollection />
      <NftStaking />
    </div>
  );
};

export default HomeCom;
