import {
  clipicon,
  collectioncardimg,
  collectionimg1,
  textBox2,
  video3,
} from "assets";
import NavbarComp from "components/homeCom/navbar";
import React from "react";
import {
  CardInnerDiv,
  CardWrapper,
  MainSection,
  Message,
  MobileCardWrapper,
  Name,
  TextBoxDiv,
  TextDiv,
} from "./elements";
import { Image } from "react-bootstrap";
import { MainCol, MainContainer, MainRow } from "components/common";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import FooterComp from "components/homeCom/footer";
const MyNft = ({
  setUserAddress,
  userAddress,
  setUserStarkKey,
  userStarkKey,
  setIsWalletConnected,
  isWalletConnected,
  userNfts,
  setClient,
}) => {
  console.log("123", userNfts);
  const cardData = [
    {
      image: collectionimg1,
      name: "CraftMyrian12 Fusion",
      clipimage: clipicon,
      video: video3,
    },
    {
      image: collectionimg1,
      name: "CraftMyrian12 Fusion",
      clipimage: clipicon,
      video: video3,
    },
  ];

  return (
    <MainSection>
      <NavbarComp
        setUserAddress={setUserAddress}
        setIsWalletConnected={setIsWalletConnected}
        setUserStarkKey={setUserStarkKey}
        userAddress={userAddress}
        isWalletConnected={isWalletConnected}
        setClient={setClient}
      />{" "}
      <div className="main-div">
        <TextBoxDiv>
          <Image className="" src={textBox2} />
          <div>
            <p className="inner-text">
              My <span>NFTS</span>
            </p>
          </div>
        </TextBoxDiv>

        <MainContainer fluid>
          <div className="carddiv">
            <MainRow>
              {userNfts?.length == 0 ? (
                <Message>
                  No Items <span>Found!</span>
                </Message>
              ) : (
                <>
                  {userNfts?.map((item) => (
                    <MainCol lg={3}>
                      <CardWrapper>
                        <Image className="innerimg" src={collectioncardimg} />
                        <CardInnerDiv>
                          <div className="innerimg2">
                            <Image src={item.video} fluid />
                            {/* <video src={item.video} autoPlay muted loop /> */}
                          </div>
                          {/* <div className="innerimg2">
                        <Image src={item?.img} />
                      </div> */}

                          <TextDiv>
                            <div className="text-div">
                              <Name>{item.name}</Name>
                              <div className="img-div">
                                <Image src={item.clipimage} />
                              </div>
                            </div>
                          </TextDiv>
                        </CardInnerDiv>
                      </CardWrapper>
                    </MainCol>
                  ))}
                </>
              )}
            </MainRow>
          </div>

          <div className="mobile-carddiv">
            <Swiper
              navigation={false}
              modules={[Navigation]}
              className="mySwiper"
              slidesPerView={1.3}
              spaceBetween={20}
            >
              {userNfts?.length == 0 ? (
                <>
                  <Message>
                    No Items <span>Found!</span>
                  </Message>
                </>
              ) : (
                <>
                  {userNfts?.map((item) => (
                    <SwiperSlide>
                      <MobileCardWrapper>
                        <Image className="innerimg" src={collectioncardimg} />

                        <CardInnerDiv>
                          <div className="innerimg2">
                            <Image className="inner-img" src={item.image} />
                          </div>

                          <TextDiv>
                            <div className="text-div">
                              <Name>{item.name}</Name>
                              <div className="img-div">
                                <Image src={item.clipimage} />
                              </div>
                            </div>
                          </TextDiv>
                        </CardInnerDiv>
                      </MobileCardWrapper>
                    </SwiperSlide>
                  ))}
                </>
              )}
            </Swiper>
          </div>
        </MainContainer>
      </div>
    </MainSection>
  );
};

export default MyNft;
