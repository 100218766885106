import React, { useRef, useState } from "react";
import {
  ArrowDiv,
  BtnText,
  CardDiv,
  CardInnerDiv,
  CardWrapper,
  Imgdiv,
  InfoDiv,
  LastText,
  MainArrowDiv,
  MainDev,
  MainSection,
  MainWrapper,
  MainWrapper2,
  MintbtnDiv,
  MobileCardWrapper,
  Name,
  SwiperDiv,
  Text,
  TextBoxDiv,
  TextDiv,
  Title,
  Value,
} from "./elements";
import { Image } from "react-bootstrap";
import {
  arrowicon,
  cardimg2,
  clipicon,
  collectionbgimg1,
  collectionbtnimg1,
  collectioncardimg,
  collectionimg1,
  ethicon,
  s3img,
  textBox,
  textBox2,
  video3,
} from "assets";
import { MainCol, MainContainer, MainRow } from "components/common";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
const MyCollection = () => {
  const cardData = [
    {
      image: collectionimg1,
      video: video3,
      name: "CraftMyrian Fusion",
      ethimage: ethicon,
      clipimage: clipicon,
    },
    {
      image: collectionimg1,
      video: video3,
      name: "CraftMyrian Fusion",
      ethimage: ethicon,
      clipimage: clipicon,
    },
    {
      image: collectionimg1,
      video: video3,
      name: "CraftMyrian Fusion",
      ethimage: ethicon,
      clipimage: clipicon,
    },
    {
      image: collectionimg1,
      video: video3,
      name: "CraftMyrian Fusion",
      ethimage: ethicon,
      clipimage: clipicon,
    },
  ];
  // const [isHovered, setIsHovered] = useState(false);

  const [clickedCards, setClickedCards] = useState(
    Array(cardData.length).fill(false)
  );

  const handleCardClick = (index) => {
    const newClickedCards = [...clickedCards];
    newClickedCards[index] = !newClickedCards[index];
    setClickedCards(newClickedCards);
  };
  return (
    <MainSection id="collection">
      <TextBoxDiv>
        <Image className="" src={textBox2} />
        <div>
          <p className="inner-text">
            Our <span>Collection</span>
          </p>
        </div>
      </TextBoxDiv>
      <MainContainer fluid>
        <div className="carddiv">
          <MainRow>
            {cardData.map((item, index) => (
              <MainCol lg={3}>
                <CardWrapper
                  // onMouseEnter={() => setIsHovered(true)}
                  // onMouseLeave={() => setIsHovered(false)}
                  onClick={() => handleCardClick(index)}
                >
                  <Image className="innerimg" src={collectioncardimg} />
                  <CardInnerDiv>
                    <div className="innerimg2">
                      <Image src={item.video} fluid />
                      {/* <video src={item.video} autoPlay muted loop /> */}
                    </div>

                    <TextDiv>
                      <div className="text-div">
                        <Name>{item.name}</Name>
                        <div className="img-div">
                          <Image src={item.clipimage} />
                        </div>
                      </div>
                    </TextDiv>
                  </CardInnerDiv>
                </CardWrapper>
              </MainCol>
            ))}
          </MainRow>
        </div>

        <div className="mobile-carddiv">
          <Swiper
            navigation={false}
            modules={[Navigation]}
            className="mySwiper"
            slidesPerView={1.3}
            spaceBetween={20}
          >
            {cardData.map((item, index) => (
              <SwiperSlide>
                <MobileCardWrapper onClick={() => handleCardClick(index)}>
                  <Image className="innerimg" src={collectioncardimg} />

                  <CardInnerDiv>
                    <div className="innerimg2">
                      <Image src={item.video} fluid />
                      {/* <video src={item.video} autoPlay muted loop /> */}
                    </div>

                    <TextDiv>
                      <div className="text-div">
                        <Name>{item.name}</Name>
                        <div className="img-div">
                          <Image src={item.clipimage} />
                        </div>
                      </div>
                    </TextDiv>
                  </CardInnerDiv>
                </MobileCardWrapper>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* <LastText>See all</LastText> */}
      </MainContainer>
    </MainSection>
  );
};

export default MyCollection;
