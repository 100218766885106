export const Images = {
  web3: {
    metamask: require("./web3/metamask.png"),
    wallet: require("./web3/wallet.png"),
  },
  login: {
    bg: require("./images/bg-img.png"),
  },
};

export { default as logo1 } from "./images/logo1.png";

export { default as twittericon } from "./images/twitter.png";
export { default as telegramicon } from "./images/telegram.png";

export { default as cardimg } from "./images/cardimg.png";

export { default as collectioncardimg } from "./images/collectioncardimg.png";
export { default as collectionimg1 } from "./images/collectionimg1.png";

export { default as twittericons } from "./images/twittericon.png";
export { default as mailicons } from "./images/mailicon.png";
export { default as mailicons2 } from "./images/mailicon2.png";

export { default as youtubeicons } from "./images/youtubeicon.png";
export { default as telegramicons1 } from "./images/telegramicon1.png";
export { default as telegramicons2 } from "./images/telegramicon2.png";
export { default as soonimg } from "./images/soonimg.png";
export { default as mobilesoonimg } from "./images/mobilesoonimg.png";

export { default as textBox } from "./images/textBox.svg";
export { default as textBox2 } from "./images/textBox2.svg";
export { default as clipicon } from "./images/clipicon.png";
export { default as ethicon } from "./images/ethicon.png";
export { default as modalimg } from "./images/modalimg.png";
export { default as heroimg } from "./images/heroimg.png";

export { default as twsvg } from "./images/tsvg.svg";
export { default as tsvg1 } from "./images/tsvg1.svg";
export { default as tsvg2 } from "./images/tsvg2.svg";
export { default as ysvg } from "./images/ysvg.svg";
export { default as msvg } from "./images/msvg.svg";

export { default as video3 } from "./images/videogif.gif";
