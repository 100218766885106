import styled from "styled-components";

export const MainSection = styled.section`
  padding-bottom: 7rem;
  margin-top: 2rem;

  @media (max-width: 767px) {
    padding-bottom: 3rem;
    margin-top: -5rem;
  }
  .textbox-div {
    display: flex;
    justify-content: end;
    align-items: end;
  }

  .swiper {
    /* width: 326.684px;
    height: 482.058px; */
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-slide:nth-child(2n) {
    /* background-color: yellow; */
  }
`;

export const ImgDiv = styled.div`
  margin-top: 2.5rem;
  img {
    width: 100%;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const MobileImgDiv = styled.div`
  margin-top: 1rem;
  img {
    width: 100%;
  }

  @media (min-width: 767px) {
    display: none;
  }
`;

export const TextBoxDiv = styled.div`
  margin-top: 2rem;
  position: relative;

  width: 37.5625rem;
  height: 5.5rem;

  @media (max-width: 1600px) {
    width: 25.5625rem;
  }

  @media (max-width: 600px) {
    width: 300px;
  }

  @media (max-width: 767px) {
    margin-top: 8rem;
  }

  img {
    width: 100% !important;
    height: 100% !important;
  }

  div {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 2rem;
    p {
      margin: 0;
      color: #fff;
      font-size: 3.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 4.82794rem; /* 154.494% */
      letter-spacing: -0.0625rem;

      @media (max-width: 1600px) {
        font-size: 2.125rem;
      }
      @media (max-width: 767px) {
        font-size: 1.74063rem;
      }

      span {
        background: var(
          --yellow,
          linear-gradient(180deg, #e0c528 0%, #f7c13a 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        font-size: 3.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 4.82794rem;
        letter-spacing: -0.0625rem;

        @media (max-width: 1600px) {
          font-size: 2.125rem;
        }

        @media (max-width: 767px) {
          font-size: 1.74063rem;
        }
      }
    }
  }
`;
