import {
  MainModel,
  ModelsData,
  ConnectButton,
  ConnectIcon,
  Text,
  MainDiv,
  Title,
} from "./connectModalElement";
import { Images, connectbtn, modalimg } from "../../../../assets";
import { useWalletConnectClient } from "services/walletServices";
import environment from "environment";
import { Image } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
const ConnectModal = ({ closeModel }) => {
  const { connect, connectWithInjected } = useWalletConnectClient();
  const handleWeb3MetaMask = async () => {
    connectWithInjected(environment.DEFAULT_CHAIN);
    closeModel();
  };

  const handleWeb3WalletConnect = async () => {
    connect(`eip155:${environment.DEFAULT_CHAIN}`);
    closeModel();
  };

  return (
    <MainModel>
      <ModelsData>
        <div className="icon">
          <IoMdClose
            color="#E1C529"
            fontSize={20}
            cursor="pointer"
            onClick={closeModel}
          />
        </div>
        <Title>
          Connect <span>Wallet</span>
        </Title>
        <div className="btn-div" onClick={() => handleWeb3MetaMask()}>
          <Image src={modalimg} fluid />
          <div className="text-div">
            <div className="metamask-div">
              <Image src={Images.web3.metamask} className="imgs" />
              <Text>Metamask</Text>
            </div>
          </div>
        </div>

        <div
          className="btn-div"
          onClick={() => {
            handleWeb3WalletConnect();
          }}
        >
          <Image src={modalimg} fluid />
          <div className="text-div">
            <div className="metamask-div2">
              <Image src={Images.web3.wallet} className="imgs" />
              <Text>Wallet Connect</Text>
            </div>
          </div>
        </div>
        {/* <div className="btn-div">
          <Image src={connectbtn} className="btn-img" />
          <div className="text-div">
            <Text>connect WALLET</Text>
          </div>
        </div>
        <div className="main-dev">
          <MainDiv onClick={() => handleWeb3MetaMask()}>
            <Image src={Images.web3.metamask} className="imgs" />
            <h5>Metamask</h5>
          </MainDiv>
          <MainDiv
            style={{ marginTop: "2rem" }}
            onClick={() => {
              handleWeb3WalletConnect();
            }}
          >
            <Image src={Images.web3.wallet} className="imgs" />
            <h5>Wallet Connect</h5>
          </MainDiv>
        </div> */}
      </ModelsData>
    </MainModel>
  );
};

export default ConnectModal;
